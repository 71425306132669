import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountPlusOutline, mdiAccountRemoveOutline, mdiCogOutline, mdiDatabaseOutline, mdiDnsOutline, mdiPencilOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useSetting() {
    // 	Name       string `json:"name"`       // 配置名称
    // Key        string `json:"key"`        // 配置key
    // Value      string `json:"value"`      // 配置内容
    // Ext        string `json:"ext"`        // 扩展信息
    // Desc       string `json:"desc"`       // 描述说明
    // Changeable int32  `json:"changeable"` // 是否可以修改 1=可修改 2=不可修改
    // Deletable  int32  `json:"deletable"`  // 是否可以删除 1=可删除 2=不可删除
    const headers = [
        { text: '配置名称', value: 'name' },
        { text: '代码', value: 'key' },
        { text: '值', value: 'value' },
        // { text: '扩展信息', value: 'ext' },
        { text: '描述', value: 'desc' },
        { text: '是否可修改', value: 'changeable' },
        { text: '是否可删除', value: 'deletable' },
        { text: '操作', value: 'actions', align: 'center', sortable: false }
    ]
    const param = ref({
        query: '',
        options: { sortBy: ['id'], sortDesc: [true], page: 1, itemsPerPage: 10 }
    })
    const table = ref({
        list: [],
        total: 0,
        snak: {
            open: false,
            msg: ''
        },
        lodading: false,
        genders: {
            1: "男",
            2: "女",
        },
        authTypes: {
            MAN_USER_MOBILE: '人工认证'
        },
        authStatus: {
            1: "成功",
            2: "失败",
            3: "待审核",
            4: "待短信验证",
        },
        dialogRefuse: {
            open: false,
            item: { id: '', nickname: '', real_name: '', failed_reason: '' }
        },
        dialogAdd: {
            open: false, loading: false,
            content: { changeable: 1, deletable: 1, desc: "", ext: "", key: "", name: "", value: "" }
        },
        dialogEdit: {
            open: false, loading: false,
            content: { changeable: 1, deletable: 2, desc: "", ext: "", id: "", key: "", name: "", value: "" }
        },
        dialogRemove: {
            open: false, loading: false,
            content: { changeable: 1, deletable: 2, desc: "", ext: "", id: "", key: "", name: "", value: "" }
        }
    })
    const enums = {
        bools: { "1": "是", "2": "否" },
        boolsColor: { "1": "warning", "2": "info" },
    }

    const toAdd = () => {
        table.value.dialogAdd = {
            open: true, loading: false,
            content: { changeable: 1, deletable: 1, desc: "", ext: "", key: "", name: "", value: "" }
        }
    }
    const toEdit = (item) => {
        table.value.dialogEdit = {
            open: true, loading: false,
            content: item
        }
    }
    const toRemove = (item) => {
        table.value.dialogRemove = {
            open: true, loading: false,
            content: item
        }
    }
    const fetchSettingList = () => {
        table.value.loading = true
        store.dispatch('system-setting/fetchSettingList', param.value).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            const { list, total } = res.data.data
            table.value.list = list || []
            table.value.total = total
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }
    const addSetting = () => {
        table.value.loading = true
        const data = { ...table.value.dialogAdd.content }
        store.dispatch('system-setting/addSetting', data).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogAdd.open = false
            fetchSettingList()
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }
    const updateSetting = () => {
        table.value.loading = true
        const data = { ...table.value.dialogEdit.content }
        store.dispatch('system-setting/updateSetting', data).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogEdit.open = false
            fetchSettingList()
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }
    const delSetting = () => {
        table.value.loading = true
        const data = { ...table.value.dialogRemove.content }
        store.dispatch('system-setting/delSetting', data).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogRemove.open = false
            fetchSettingList()
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }

    const applyAccept = (item) => {
        table.value.loading = true
        store.dispatch('system-setting/fetchSettingAccept', item).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value.snak = {
                    open: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            fetchSettingList()
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }

    const applyRefuse = () => {
        table.value.loading = true
        store.dispatch('system-setting/fetchSettingRefuse', table.value.dialogRefuse.item).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value.snak = {
                    open: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            table.value.dialogRefuse.open = false
            fetchSettingList()
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }

    watch([param], () => {
        fetchSettingList()
    }, { deep: true })
    return {
        headers,
        param,
        table, enums,
        toAdd, toEdit, toRemove, addSetting, updateSetting, delSetting,
        fetchSettingList, applyAccept, applyRefuse
    }
}
