<template>
    <div id='user-list'>
        <v-dialog v-model="table.dialogAdd.open" persistent max-width="600">
            <v-card>
                <v-card-title>
                    <span class="headline">添加参数配置</span>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="table.dialogAdd.content.name" label="配置名称"
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="table.dialogAdd.content.key" label="Key" hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="table.dialogAdd.content.value" label="值" hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea v-model="table.dialogAdd.content.desc" label="描述" hide-details></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="info" outlined @click="table.dialogAdd.open = false"> 取消 </v-btn>
                    <v-btn color="primary" outlined @click="addSetting"> 提交添加 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="table.dialogEdit.open" persistent max-width="600">
            <v-card>
                <v-card-title>
                    <span class="headline">编辑参数配置</span>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="table.dialogEdit.content.name" label="配置名称"
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="table.dialogEdit.content.key" label="Key"
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="table.dialogEdit.content.value" label="值"
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea v-model="table.dialogEdit.content.desc" label="描述" hide-details></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="info" outlined @click="table.dialogEdit.open = false"> 取消 </v-btn>
                    <v-btn color="warning" outlined @click="updateSetting"> 提交更新 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="table.dialogRemove.open" persistent max-width="600">
            <v-card>
                <v-card-title>
                    <span class="headline">删除参数配置</span>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialogRemove.content.name" label="配置名称"
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialogRemove.content.key" label="Key"
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialogRemove.content.value" label="值"
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea readonly v-model="table.dialogRemove.content.desc" label="描述"
                                    hide-details></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="info" outlined @click="table.dialogRemove.open = false"> 取消 </v-btn>
                    <v-btn color="error" outlined @click="delSetting"> 确认删除 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="table.snak.open" multi-line :light="$vuetify.theme.dark" :timeout="2000">
            {{ table.snak.msg }}
            <template #action="{ attrs }">
                <v-btn color="error" text v-bind="attrs" @click="table.snak.open = false"> 关闭 </v-btn>
            </template>
        </v-snackbar>
        <v-card>
            <v-divider class='mt-4'></v-divider>
            <v-card-text class='d-flex align-center flex-wrap pb-4'>
                <v-text-field v-model='param.query' class='user-search me-3 mb-0' dense hide-details outlined
                    placeholder='搜索配置'></v-text-field>
                <v-spacer></v-spacer>
                <v-btn @click="toAdd()" color="primary" dark>添加
                    <v-icon dark right> {{ icons.mdiPlus }} </v-icon>
                </v-btn>
            </v-card-text>
            <!-- table -->
            <v-data-table :headers='headers' :items='table.list' :loading='table.loading' :options.sync='param.options'
                :server-items-length='table.total' show-select>
                <template #[`item.changeable`]='{ item }'>
                    <v-chip :color="enums.boolsColor[item.changeable]">{{ enums.bools[item.changeable] }}</v-chip>
                </template>
                <template #[`item.deletable`]='{ item }'>
                    <v-chip :color="enums.boolsColor[item.deletable]">{{ enums.bools[item.deletable] }}</v-chip>
                </template>
                <template #[`item.auth_state`]='{ item }'>
                    {{ table.authStatus[item.auth_state] || '-' }}
                </template>
                <template #[`item.gender`]='{ item }'>
                    {{ table.genders[item.gender] || '-' }}
                </template>
                <!-- actions -->
                <template #[`item.actions`]='{ item }'>
                    <v-row dense>
                        <v-col v-if="item.changeable == 1">
                            <v-btn @click="toEdit(item)" color="warning" dark small>编辑
                                <v-icon dark right> {{ icons.mdiCheckboxMarkedCircleOutline }} </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col v-if="item.deletable == 1">
                            <v-btn @click="toRemove(item)" color="error" small>删除
                                <v-icon dark right> {{ icons.mdiCancel }} </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiDeleteOutline, mdiDotsVertical, mdiExportVariant, mdiPencil, mdiPlus, mdiCancel, mdiSquareEditOutline, mdiCheckboxMarkedCircleOutline } from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import systemStoreModule from '../systemStoreModule'
import useSettingList from './useSetting'

export default {
    components: {}, setup() {
        const USER_APP_STORE_MODULE_NAME = 'system-setting'
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, systemStoreModule)
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const { headers, param, table, enums, toAdd, toEdit, toRemove, addSetting, updateSetting, delSetting, fetchSettingList, applyAccept, applyRefuse } = useSettingList()

        fetchSettingList()

        const toRefuse = (item) => {
            table.value.dialogRefuse = {
                open: true,
                item: item
            }
        }

        return {
            headers,
            param,
            table, enums,
            toAdd, toEdit, toRemove, addSetting, updateSetting, delSetting,
            fetchSettingList, applyAccept, applyRefuse,
            toRefuse,
            // icons
            icons: { mdiSquareEditOutline, mdiPencil, mdiDotsVertical, mdiDeleteOutline, mdiPlus, mdiCancel, mdiExportVariant, mdiAccountOutline, mdiCheckboxMarkedCircleOutline },
        }
    },
} </script>
<style lang='scss'> @import '@core/preset/preset/apps/user.scss';
</style>
